.tree {
    height: 87vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: auto;
}

.sidebar-flex {
    display: inline-flex;
}

.sidebar-open {
    display: inline-table;
}

.sidebar-close {
    display: inline-grid;
}