:root {
    --dimension: 100px;
    --thickness: 4px;
    --color: red;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}


.arc {
    position: relative;
    width: var(--dimension);
    height: var(--dimension);
}

    .arc:before, .arc:after {
        border-bottom: var(--thickness) solid var(--color);
        border-left: var(--thickness) solid transparent;
        border-radius: 50%;
        border-right: var(--thickness) solid var(--color);
        border-top: var(--thickness) solid var(--color);
        bottom: 0;
        box-sizing: border-box;
        content: '';
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: center center;
    }

    .arc:before {
        animation: rotate 1s ease-in-out infinite;
        height: 100%;
        width: 100%;
    }

    .arc:after {
        animation: rotate 1s ease-in-out infinite reverse;
        height: 50%;
        width: 50%;
    }
